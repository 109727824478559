<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Video Input</v-card-title>
        <div class="video-container">
          <video-player :input="videoInput.video" />
        </div>

        <v-form class="pa-5 mt-5" ref="form" lazy-validation>
          <v-select
            outlined
            type="text"
            label="Status"
            prepend-icon="mdi-format-list-bulleted-square"
            :items="statusTypes"
            item-text="type"
            item-value="value"
            v-model="videoInput.status"
            :rules="[rule.required(videoInput.status)]"
          />
          <v-textarea
            outlined
            label="Comment"
            prepend-icon="mdi-text"
            v-model="videoInput.comment"
          >
          </v-textarea>

          <v-row class="mt-3" justify="end">
            <v-btn
              color="primary"
              :loading="getVideoInputsLoading"
              :disabled="getVideoInputsLoading"
              @click="save"
            >
              Save Changes
            </v-btn>
          </v-row>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import rules from "@/shared/rules";
import { find, get, omit } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Video Input",
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      rule: rules,
      id: _.get(this.$route, "params.id"),
      statusTypes: [
        { type: "New", value: "new" },
        { type: "Correct", value: "correct" },
        { type: "Incorrect", value: "incorrect" },
      ],
      hasError: false,
    };
  },
  methods: {
    ...mapActions(["updateVideoInput"]),

    async save() {
      this.$store.dispatch("setVideoInputsLoading", true);

      try {
        const payload = {
          status: get(this.videoInput, "status"),
          comment: get(this.videoInput, "comment"),
        };

        const result = await this.updateVideoInput({
          id: this.id,
          payload,
        });

        this.$store.dispatch("showSnackbar", result);
      } catch (error) {
        console.error("DEBUG: error", error);
      }

      this.$store.dispatch("setVideoInputsLoading", false);
    },
  },
  computed: {
    ...mapGetters(["getVideoInputs", "getVideoInputsLoading"]),
    videoInput() {
      return {
        status: null,
        comment: null,
        ...omit(find(this.getVideoInputs, { id: +this.id }), ["id"]),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.video-container {
  max-width: 550px;
  margin: 0 auto;
  color: white;
}

label {
  width: 130px !important;
}
</style>
