<template>
  <vue-plyr :options="playerOptions">
    <video crossorigin playsinline :src="input"></video>
  </vue-plyr>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    input: {
      type: String,
      default() {
        return "";
      },
    },
    playerOptions: {
      type: Object,
      required: false,
      default() {
        return {
          controls: [
            "play-large",
            "play",
            "progress",
            "current-time",
            "mute",
            "volume",
            "download",
            "fullscreen",
          ],
        };
      },
    },
  },
};
</script>
